import React from 'react'
import "./footer.css";

function Footer() {
  return (
<footer>&copy; Copyright 2024 nikthe.tech</footer>
    
  )
}

export default Footer